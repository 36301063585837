import { motion } from 'framer-motion'

import PageContainer from '../PageContainer'
import './About.css'
import PLACEHOLDER from '../../assets/about-placeholder2.jpg'

const aboutTextVariant = {
  initial : {
    y : '100%',
    opacity : 0,
  },
  animate : {
    y : 0,
    opacity : 1,
    transition : {
      duration : 1,
      ease : 'easeInOut',
      when : 'beforeChildren'
    }
  },
  exit : {
    y : '-100%',
    opacity : 0,
    transition : {
      duration : .5,
      ease : 'easeInOut'
    }
  },
  whileHover : {}
}

const aboutTextSpanVariant = {
  initial : {
    scale : 1
  },
  animate : {
    color : 'rgb(150, 255, 255)',
    scale : 1.1,
    transition : {
      duration : 1,
      ease : 'easeInOut'
    }
  },
}

const About = props => {
  const classNameConfig = {
    pageContainerClasses : "About About-Color",
    pageContentClasses : "About-Content"
  }

  return (
    <PageContainer classNameConfig={classNameConfig} title="ABOUT">
        <motion.div className="About-Content-Block ">
          <motion.h1
            variants={aboutTextVariant}
            whileHover="whileHover" >
            I'm deeply interested in technology and how it shapes our world today. 
          </motion.h1>
          <div className="spacer" />
          < motion.p variants={aboutTextVariant} whileHover="whileHover">
          However, I'm just as much a skeptic as I am a believer. Software has increased our collective quality of life through easier 
          access to education, financial tools, and opportunities. But it has also created massive inequality and has morphed capitalism into something
            that somewhat resembles technofeudalism. Technology both projects and morphs existing social structures; it can change the world and exaggerate its
            worst tendencies. Like Kendall says in S3EP8 of Succession, "Maybe the poison drips through." But maybe not! Its more fun being optimistic anyway.
          </motion.p>
          <div className="spacer-sm" />
          <motion.p
            variants={aboutTextVariant}
            whileHover="whileHover" >
            I work at <motion.span variants={aboutTextSpanVariant} initial="initial" animate="animate">@Salesforce</motion.span> and 
            live in San Francisco. I'm a Connecticut native, and have spent most of my career bouncing around the east coast, the 
            world, and any place with good WiFi. If you find me interesting at all, shoot me a message on any of my platforms!
          </motion.p>
        </motion.div>
        <motion.div className='About-Img-Container About-Content-Block'>
          {/* <motion.h1 variants={aboutTextVariant} whileHover="whileHover">Hello!</motion.h1> */}
          <motion.img src={PLACEHOLDER} alt="placeholder" className="About-Img"/>
        </motion.div>
    </PageContainer>
  )
}

export default About